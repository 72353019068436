// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.login-content {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: hsl(0, 0%, 98%);
    margin-top: 100px;

  }
  
  .login-content h2 {
    text-align: center;
    margin-bottom: 20px;
  }

  .login-content p {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;

  }
  

.password-reset-link {
  text-align: right;
}

.login-button {
  width: 100%;
  padding: 10px;
  background-color: #90DC1E;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.login-button:hover {
  background-color: #477109;
}

.login-button:active {
  transform: scale(0.98);
}

.login-button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}`, "",{"version":3,"sources":["webpack://./src/css/Login.css"],"names":[],"mappings":";AACA;IACI,WAAW;IACX,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,iCAAiC;IACjC,iBAAiB;;EAEnB;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,sBAAsB;;EAExB;;;AAGF;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,2DAA2D;AAC7D;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;EACtB,mBAAmB;AACrB","sourcesContent":["\n.login-content {\n    width: 100%;\n    max-width: 400px;\n    margin: 0 auto;\n    padding: 20px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    background-color: hsl(0, 0%, 98%);\n    margin-top: 100px;\n\n  }\n  \n  .login-content h2 {\n    text-align: center;\n    margin-bottom: 20px;\n  }\n\n  .login-content p {\n    text-align: center;\n    margin-bottom: 20px;\n  }\n  \n  .login-form {\n    display: flex;\n    flex-direction: column;\n  }\n  \n  .form-group {\n    margin-bottom: 15px;\n  }\n  \n  .form-group label {\n    display: block;\n    margin-bottom: 5px;\n  }\n  \n  .form-group input {\n    width: 100%;\n    padding: 8px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    box-sizing: border-box;\n\n  }\n  \n\n.password-reset-link {\n  text-align: right;\n}\n\n.login-button {\n  width: 100%;\n  padding: 10px;\n  background-color: #90DC1E;\n  color: white;\n  border: none;\n  border-radius: 5px;\n  font-size: 16px;\n  cursor: pointer;\n  transition: background-color 0.3s ease, transform 0.2s ease;\n}\n\n.login-button:hover {\n  background-color: #477109;\n}\n\n.login-button:active {\n  transform: scale(0.98);\n}\n\n.login-button:disabled {\n  background-color: #ddd;\n  cursor: not-allowed;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
