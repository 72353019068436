// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notice-content {
    padding: 20px;
    background-color: #f9f9f9; /* 부드러운 배경색 */
    font-size: 1rem;
    color: #333;
    border-radius: 8px; /* 모서리 둥글게 */
    margin: 20px auto;

    max-width: 1200px; /* 최대 넓이 제한 */
  }
  
  .notice-content ul {
    list-style-type: none; /* 기본 불릿 제거 */
    padding: 0;
  }
  
  .notice-content li {
    background-color: #90dc1e; /* 리스트 아이템 배경색 */
    color: #fff;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease; /* 호버 애니메이션 */
  }
  
  .notice-content li:hover {
    background-color: #76b814; /* 호버 시 색상 변경 */
  }
  `, "",{"version":3,"sources":["webpack://./src/css/Notice.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB,EAAE,aAAa;IACxC,eAAe;IACf,WAAW;IACX,kBAAkB,EAAE,YAAY;IAChC,iBAAiB;;IAEjB,iBAAiB,EAAE,aAAa;EAClC;;EAEA;IACE,qBAAqB,EAAE,aAAa;IACpC,UAAU;EACZ;;EAEA;IACE,yBAAyB,EAAE,gBAAgB;IAC3C,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,sCAAsC,EAAE,aAAa;EACvD;;EAEA;IACE,yBAAyB,EAAE,eAAe;EAC5C","sourcesContent":[".notice-content {\n    padding: 20px;\n    background-color: #f9f9f9; /* 부드러운 배경색 */\n    font-size: 1rem;\n    color: #333;\n    border-radius: 8px; /* 모서리 둥글게 */\n    margin: 20px auto;\n\n    max-width: 1200px; /* 최대 넓이 제한 */\n  }\n  \n  .notice-content ul {\n    list-style-type: none; /* 기본 불릿 제거 */\n    padding: 0;\n  }\n  \n  .notice-content li {\n    background-color: #90dc1e; /* 리스트 아이템 배경색 */\n    color: #fff;\n    padding: 10px;\n    margin-bottom: 10px;\n    border-radius: 5px;\n    transition: background-color 0.3s ease; /* 호버 애니메이션 */\n  }\n  \n  .notice-content li:hover {\n    background-color: #76b814; /* 호버 시 색상 변경 */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
