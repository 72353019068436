// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* UserMenubar.css */

/* 메뉴바 컨테이너 */
.user-menubar {
    width: 200px; /* 원하는 너비로 설정 */
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* 메뉴 목록 스타일 */
  .user-menubar-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .user-menubar-mainmenu {
    display: block;
    background: none;
    border: none;
    color: #333;
    font-size: 20px;
    font-weight: 500;
    text-align: left;
    padding: 10px 0;
    cursor: pointer;
    font-family: inherit; /* Inherits the font style from the parent container */
  }
  
  
  .user-menubar-mainmenu:hover {
    color: #90DC1E;
  }
  
  /* 서브메뉴 컨테이너 */
  .user-menubar-submenu {
    list-style: none;
    padding: 0;
    margin: 5px 0 10px 20px; /* 들여쓰기 적용 */
    border-left: 2px solid #e0e0e0;
  }
  
  /* 서브메뉴 항목 스타일 */
  .user-menubar-submenu li {
    margin: 5px 0;
  }
  
  .user-menubar-submenu a {
    font-size: 14px;
    color: #555;
    text-decoration: none;
    padding: 5px;
    display: block;
    transition: color 0.2s ease;
  }
  
  .user-menubar-submenu a:hover {
    color: #76b814;
  }

  `, "",{"version":3,"sources":["webpack://./src/css/UserMenubar.css"],"names":[],"mappings":"AAAA,oBAAoB;;AAEpB,aAAa;AACb;IACI,YAAY,EAAE,eAAe;IAC7B,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,wCAAwC;EAC1C;;EAEA,cAAc;EACd;IACE,gBAAgB;IAChB,UAAU;IACV,SAAS;EACX;;EAEA;IACE,cAAc;IACd,gBAAgB;IAChB,YAAY;IACZ,WAAW;IACX,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,eAAe;IACf,eAAe;IACf,oBAAoB,EAAE,sDAAsD;EAC9E;;;EAGA;IACE,cAAc;EAChB;;EAEA,cAAc;EACd;IACE,gBAAgB;IAChB,UAAU;IACV,uBAAuB,EAAE,YAAY;IACrC,8BAA8B;EAChC;;EAEA,gBAAgB;EAChB;IACE,aAAa;EACf;;EAEA;IACE,eAAe;IACf,WAAW;IACX,qBAAqB;IACrB,YAAY;IACZ,cAAc;IACd,2BAA2B;EAC7B;;EAEA;IACE,cAAc;EAChB","sourcesContent":["/* UserMenubar.css */\n\n/* 메뉴바 컨테이너 */\n.user-menubar {\n    width: 200px; /* 원하는 너비로 설정 */\n    background-color: #f8f9fa;\n    padding: 20px;\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  }\n  \n  /* 메뉴 목록 스타일 */\n  .user-menubar-list {\n    list-style: none;\n    padding: 0;\n    margin: 0;\n  }\n  \n  .user-menubar-mainmenu {\n    display: block;\n    background: none;\n    border: none;\n    color: #333;\n    font-size: 20px;\n    font-weight: 500;\n    text-align: left;\n    padding: 10px 0;\n    cursor: pointer;\n    font-family: inherit; /* Inherits the font style from the parent container */\n  }\n  \n  \n  .user-menubar-mainmenu:hover {\n    color: #90DC1E;\n  }\n  \n  /* 서브메뉴 컨테이너 */\n  .user-menubar-submenu {\n    list-style: none;\n    padding: 0;\n    margin: 5px 0 10px 20px; /* 들여쓰기 적용 */\n    border-left: 2px solid #e0e0e0;\n  }\n  \n  /* 서브메뉴 항목 스타일 */\n  .user-menubar-submenu li {\n    margin: 5px 0;\n  }\n  \n  .user-menubar-submenu a {\n    font-size: 14px;\n    color: #555;\n    text-decoration: none;\n    padding: 5px;\n    display: block;\n    transition: color 0.2s ease;\n  }\n  \n  .user-menubar-submenu a:hover {\n    color: #76b814;\n  }\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
