// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signup-content {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    margin-top: 100px;
  }
  
  .signup-content h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .signup-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    font-weight: bold;
  }
  
  .form-group input {
    width: 100%;
    padding: 8px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  .signup-button {
    width: 100%;
    padding: 10px;
    background-color: #90DC1E;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .signup-button:disabled:hover {
    background-color: #ccc;
  }

  .signup-button:hover {
    background-color: #477109;
  }
  
  .signup-button:active {
    transform: scale(0.98);
  }
  
  .clickable-home {
    cursor: pointer;
  }

  `, "",{"version":3,"sources":["webpack://./src/css/SignUp.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,wCAAwC;IACxC,yBAAyB;IACzB,iBAAiB;EACnB;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,cAAc;IACd,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,aAAa;IACb,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,2DAA2D;EAC7D;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,eAAe;EACjB","sourcesContent":[".signup-content {\n    max-width: 400px;\n    margin: 0 auto;\n    padding: 20px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n    background-color: #f9f9f9;\n    margin-top: 100px;\n  }\n  \n  .signup-content h2 {\n    text-align: center;\n    margin-bottom: 20px;\n  }\n  \n  .signup-form {\n    display: flex;\n    flex-direction: column;\n  }\n  \n  .form-group {\n    margin-bottom: 15px;\n  }\n  \n  .form-group label {\n    font-weight: bold;\n  }\n  \n  .form-group input {\n    width: 100%;\n    padding: 8px;\n    font-size: 1em;\n    border: 1px solid #ccc;\n    border-radius: 3px;\n  }\n  \n  .signup-button {\n    width: 100%;\n    padding: 10px;\n    background-color: #90DC1E;\n    color: white;\n    border: none;\n    border-radius: 5px;\n    font-size: 16px;\n    cursor: pointer;\n    transition: background-color 0.3s ease, transform 0.2s ease;\n  }\n  \n  .signup-button:disabled:hover {\n    background-color: #ccc;\n  }\n\n  .signup-button:hover {\n    background-color: #477109;\n  }\n  \n  .signup-button:active {\n    transform: scale(0.98);\n  }\n  \n  .clickable-home {\n    cursor: pointer;\n  }\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
