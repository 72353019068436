// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-content {
    height: 60vh; /* 화면 높이의 80% */
    background-color: #90DC1E;
    margin: 0 auto; /* 가로 중앙 정렬 */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px; /* 내부 여백 */
}

.home-title {
    font-size: 2.5rem; /* 텍스트 크기 증가 */
    font-weight: bold;
    color: #333;
    text-align: center;
  }

  .home-sub-title {
    color: #333;
    text-align: center;

  }`, "",{"version":3,"sources":["webpack://./src/css/Home.css"],"names":[],"mappings":"AAAA;IACI,YAAY,EAAE,eAAe;IAC7B,yBAAyB;IACzB,cAAc,EAAE,aAAa;IAC7B,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa,EAAE,UAAU;AAC7B;;AAEA;IACI,iBAAiB,EAAE,cAAc;IACjC,iBAAiB;IACjB,WAAW;IACX,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,kBAAkB;;EAEpB","sourcesContent":[".home-content {\n    height: 60vh; /* 화면 높이의 80% */\n    background-color: #90DC1E;\n    margin: 0 auto; /* 가로 중앙 정렬 */\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 20px; /* 내부 여백 */\n}\n\n.home-title {\n    font-size: 2.5rem; /* 텍스트 크기 증가 */\n    font-weight: bold;\n    color: #333;\n    text-align: center;\n  }\n\n  .home-sub-title {\n    color: #333;\n    text-align: center;\n\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
