// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resume-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-right: 20px;
    margin-left: 10px;
}

.resume-list {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.resume-card {
    background-color: #ffffff;
    border: 1px solid #dee2e6;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 15px;
    width: calc(33.333% - 20px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    box-sizing: border-box;
}

.resume-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.resume-title {
    font-size: 1.5em;
    margin-top: 0;
}

.resume-date, .resume-description {
    font-size: 1rem;
    color: #666;
}

.loading-message, .error-message, .no-resumes-message {
    font-size: 1.2em;
    color: #555;
    margin-top: 20px;
    text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/css/MyResumeList.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,sBAAsB;IACtB,aAAa;IACb,kBAAkB;IAClB,wCAAwC;IACxC,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,qBAAqB;IACrB,aAAa;IACb,eAAe;IACf,SAAS;AACb;;AAEA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,wCAAwC;IACxC,2CAA2C;IAC3C,sBAAsB;AAC1B;;AAEA;IACI,2BAA2B;IAC3B,yCAAyC;AAC7C;;AAEA;IACI,gBAAgB;IAChB,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":[".resume-container {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    background-color: #fff;\n    padding: 20px;\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    margin-right: 20px;\n    margin-left: 10px;\n}\n\n.resume-list {\n    list-style-type: none;\n    display: flex;\n    flex-wrap: wrap;\n    gap: 20px;\n}\n\n.resume-card {\n    background-color: #ffffff;\n    border: 1px solid #dee2e6;\n    border-radius: 8px;\n    padding: 20px;\n    margin-bottom: 15px;\n    width: calc(33.333% - 20px);\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    transition: transform 0.3s, box-shadow 0.3s;\n    box-sizing: border-box;\n}\n\n.resume-card:hover {\n    transform: translateY(-5px);\n    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);\n}\n\n.resume-title {\n    font-size: 1.5em;\n    margin-top: 0;\n}\n\n.resume-date, .resume-description {\n    font-size: 1rem;\n    color: #666;\n}\n\n.loading-message, .error-message, .no-resumes-message {\n    font-size: 1.2em;\n    color: #555;\n    margin-top: 20px;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
