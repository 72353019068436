// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notice-navbar {
    display: flex;
    justify-content: center;
    padding: 15px;
    border-radius: 5px;
  }
  
  .notice-form {
    display: flex;
    align-items: center;
  }
  
  .notice-search-input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px 0 0 4px;
    outline: none;
    width: 250px;
    font-size: 16px;
  }
  
  .notice-search-button {
    padding: 10px 15px;
    background-color: #90DC1E;
    color: white;
    border: none;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .notice-search-button:hover {
    background-color: #76b814;
  }
  
  .notice-search-input:focus {
    border-color: #90DC1E;
    box-shadow: 0 0 5px rgba(144, 220, 30, 0.5);
  }
  
  .notice-sort-select {
    margin-left: 10px;
    padding: 8px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
    cursor: pointer;
  }
  
  .notice-sort-select:focus {
    border-color: #90DC1E;
    box-shadow: 0 0 5px rgba(144, 220, 30, 0.5);
  }
  `, "",{"version":3,"sources":["webpack://./src/css/NoticeNavbar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,aAAa;IACb,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,0BAA0B;IAC1B,aAAa;IACb,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,0BAA0B;IAC1B,eAAe;IACf,eAAe;IACf,sCAAsC;EACxC;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,qBAAqB;IACrB,2CAA2C;EAC7C;;EAEA;IACE,iBAAiB;IACjB,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,sBAAsB;IACtB,yBAAyB;IACzB,eAAe;EACjB;;EAEA;IACE,qBAAqB;IACrB,2CAA2C;EAC7C","sourcesContent":[".notice-navbar {\n    display: flex;\n    justify-content: center;\n    padding: 15px;\n    border-radius: 5px;\n  }\n  \n  .notice-form {\n    display: flex;\n    align-items: center;\n  }\n  \n  .notice-search-input {\n    padding: 10px;\n    border: 1px solid #ddd;\n    border-radius: 4px 0 0 4px;\n    outline: none;\n    width: 250px;\n    font-size: 16px;\n  }\n  \n  .notice-search-button {\n    padding: 10px 15px;\n    background-color: #90DC1E;\n    color: white;\n    border: none;\n    border-radius: 0 4px 4px 0;\n    cursor: pointer;\n    font-size: 16px;\n    transition: background-color 0.3s ease;\n  }\n  \n  .notice-search-button:hover {\n    background-color: #76b814;\n  }\n  \n  .notice-search-input:focus {\n    border-color: #90DC1E;\n    box-shadow: 0 0 5px rgba(144, 220, 30, 0.5);\n  }\n  \n  .notice-sort-select {\n    margin-left: 10px;\n    padding: 8px;\n    font-size: 16px;\n    border-radius: 4px;\n    border: 1px solid #ddd;\n    background-color: #f9f9f9;\n    cursor: pointer;\n  }\n  \n  .notice-sort-select:focus {\n    border-color: #90DC1E;\n    box-shadow: 0 0 5px rgba(144, 220, 30, 0.5);\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
